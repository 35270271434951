import React from 'react';
import { Paper } from '@mui/material';
import styled from '@emotion/styled';
import { Special } from '../types/Special';

const StyledSpecial = styled(Paper)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 1.1px;
  padding: 24px;
  position: relative;

  h2,
  h3 {
    font-size: 1em;
    margin: 0 0 16px;
    opacity: 0.8;
  }

  p {
    margin: 0;
    opacity: 0.8;
    text-align: left;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding-inline-start: 0;
  }

  li {
    margin-bottom: 4px;
    opacity: 0.8;
  }
`;

function SpecialCard({ description }: Special) {
  return (
    <StyledSpecial>
      <div
        dangerouslySetInnerHTML={{
          __html: description.childMarkdownRemark.html,
        }}
      />
    </StyledSpecial>
  );
}

export default SpecialCard;
