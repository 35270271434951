import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Grid } from '@mui/material';

import Layout from '../components/layout';
import SpecialCard from '../components/SpecialCard';

const SpecialsPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulSpecialsPage {
          edges {
            node {
              specials {
                ...SpecialFragment
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout pageName='Specials'>
        <Grid container spacing={8}>
          {data.allContentfulSpecialsPage.edges[0].node.specials.map((special) => (
            <Grid item xs={12}>
              <SpecialCard {...special} />
            </Grid>
          ))}
        </Grid>
      </Layout>
    )}
  />
);

export default SpecialsPage;
